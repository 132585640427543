import Vue from 'vue'

Vue.component('Admin', () => import('./Admin'))
Vue.component('Events', () => import('./Events'))
Vue.component('KarolinafundProjects', () => import('./KarolinafundProjects'))
Vue.component('NewEvent', () => import('./NewEvent'))
Vue.component('Event', () => import('./Event'))
Vue.component('EditEvent', () => import('./EditEvent'))
Vue.component('LogInModal', () => import('./LogInModal'))
Vue.component('ShareModal', () => import('./ShareModal'))
Vue.component('CreateAccountModal', () => import('./CreateAccountModal'))
Vue.component('LogIn', () => import('./LogIn'))
Vue.component('CreateAccount', () => import('./CreateAccount'))
Vue.component('EventCard', () => import('./EventCard'))
Vue.component('EventTicket', () => import('./EventTicket'))
Vue.component('PasswordReset', () => import('./PasswordReset'))
Vue.component('Search', () => import('./Search'))

// This imports all components:
// import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// Vue.use(BootstrapVue)
// Vue.use(BootstrapVueIcons)

import {
  LayoutPlugin,
  ModalPlugin,
  ButtonPlugin,
  CardPlugin,
  NavbarPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormDatepickerPlugin,
  FormInputPlugin,
  ImagePlugin,
  LinkPlugin,
  OverlayPlugin,
  BIconGridFill,
  BIconCalendarEventFill,
  BIconClockFill,
  BIconGeoAltFill,
  BIconChevronUp,
  BIconChevronDown,
  BIconLink45deg,
  BIconX,
  BIconGear,
  BIconInfoCircle,
  BIconTagsFill,
  BIconFacebook,
} from 'bootstrap-vue'
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(ButtonPlugin)
Vue.use(CardPlugin)
Vue.use(NavbarPlugin)
Vue.use(FormPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormInputPlugin)
Vue.use(ImagePlugin)
Vue.use(LinkPlugin)
Vue.use(OverlayPlugin)

Vue.component('BIconGridFill', BIconGridFill)
Vue.component('BIconCalendarEventFill', BIconCalendarEventFill)
Vue.component('BIconClockFill', BIconClockFill)
Vue.component('BIconGeoAltFill', BIconGeoAltFill)
Vue.component('BIconChevronUp', BIconChevronUp)
Vue.component('BIconChevronDown', BIconChevronDown)
Vue.component('BIconLink45deg', BIconLink45deg)
Vue.component('BIconX', BIconX)
Vue.component('BIconGear', BIconGear)
Vue.component('BIconInfoCircle', BIconInfoCircle)
Vue.component('BIconTagsFill', BIconTagsFill)
Vue.component('BIconFacebook', BIconFacebook)

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../styles/bootstrap-entry.scss'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.directive('validity', function (el, binding) {
  el.setCustomValidity(binding.value ? binding.value : '')
})

Vue.filter('truncate', function (text, stop, clamp = '...') {
  if (text && text.length > stop) {
    let trimmedString = text.substr(0, stop)
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
    )
    return trimmedString + clamp
  } else {
    return text
  }
})

import VueSnip from 'vue-snip'
const options = {
  separators: [' '],
}

Vue.use(VueSnip, options)

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing)

Vue.config.productionTip = false

const mountEl = document.querySelector('#app')

new Vue({
  delimiters: ['${', '}'],
  props: {
    userString: String,
  },
  data() {
    return {}
  },
  computed: {
    absoluteUrl() {
      return this.apiUrl + this.currentPath
    },
    user() {
      return JSON.parse(this.userString)
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL
    },
    karolinafundApiUrl() {
      return process.env.VUE_APP_KAROLINAFUND_API_URL
    },
    changeLangQuery() {
      const pathString = window.location.pathname
      const pathArray = pathString.split('/')
      let query = '?redirect=yes'
      pathArray.forEach((item, index) => {
        if (item !== '') {
          query += '&' + index + '=' + item
        }
      }, query)
      return query
    },
    currentPath() {
      return window.location.pathname
    },
    isUserAdmin() {
      return this.user && this.user.group_id === '1'
    },
  },
  propsData: { ...mountEl.dataset },
}).$mount('#app')
